import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import userInfo from '@/store/modules/userInfo';
import {useRouter} from 'vue-router'



const request = axios.create({
    baseURL: '/api',
    timeout: 50000,
})

//加载动画定义
let loading: any;

const startLoading = () => {

    interface Options {
        lock: boolean;
        text: string;
        background: string;
    }

    const options: Options = {
        lock: true,
        text: "加载中...",
        background: 'rgba(0,0,0,0)'
    }
    loading = ElLoading.service(options);
}

const endLoading = () => {
    loading.close();
}
//请求拦截
request.interceptors.request.use((config:AxiosRequestConfig) => {
    let token = JSON.parse(JSON.stringify(userInfo.state.token)).token;
    if (token) {
        if (config && config.headers) {
            config.headers["user_token"] = token;
            config.headers["user_type"] = '2';
        }
        // config.headers = {Authorization : token, ...config.headers};
    }
    //运行加载动画
    startLoading();
    return config;
})

//路由跳转工具
const router = useRouter();

request.interceptors.response.use((response:AxiosResponse<any>) => {
    if (response.data.errorCode == 206) {
        window.location.href = '/login'
    }
    if (!response.data.success||response.data.errorCode != 200) {
        ElMessage.error(response.data.errorMessage)
    }
    //结束加载动画
    endLoading();
    return response;
}, error => {
    //结束加载动画
    endLoading();
    //提示异常
    ElMessage.error("请求异常，请稍后重试！")
    return Promise.reject(error);
})


export default request;