
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue';
import {checkPhone, loginUser} from '@/http/api/login';
import { ElNotification } from 'element-plus';
import userInfo from '@/store/modules/userInfo';
import {useRouter} from 'vue-router'

//基础接口定义
interface DataProps {
    loginUser: Object;
    //数据监测规则
    rules: Object;
    //登录数据是否通过监测
    checkPass: {
                //手机号是否通过校验
                phonePass: boolean,
                //密码是否通过校验
                passwordPass: boolean
            };
    //登录
    loginSubmit: () => void;
    //重置
    resetForm: (name:String) => void;
}
export default defineComponent({
    setup() {
        //路由跳转工具
        const router = useRouter();
        //校验手机号
        const checkPhones = (rule: any, value: any, callback: any) => {
            if (!value) {
                return callback(new Error("手机号不能为空")) 
            }
            let p = /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/;
            if (!p.test(value)) {
                return callback(new Error("手机号格式不正确"))
            }
            //设置手机号为校验通过
            data.checkPass.phonePass = true;
            return callback();
        }
        //校验密码
        const checkPassword = (rule: any, value: any, callback: any) => {
            if (!value) {
                return callback(new Error("密码不能为空"))
            }
            //设置密码为校验通过
            data.checkPass.passwordPass = true;
            return callback();
        }
        const data : DataProps = reactive({
            //数据
            loginUser : {
                phone: '',
                password: '',
                //构造函数
                constructor(phone:string, password:string){
                    this.phone = phone;
                    this.password = password
                }
            },
            rules :{
                phone: [{validator: checkPhones, trigger: 'blur'}],
                password: [{validator: checkPassword, trigger: 'blur'}]
            },
            checkPass: {
                //手机号是否通过校验
                phonePass: false,
                //密码是否通过校验
                passwordPass: false
            },
            //方法
            loginSubmit: () =>{
                if (data.checkPass.phonePass &&data.checkPass.passwordPass){
                    loginUser(data.loginUser).then((res)=>{
                        if (res.data.data) {
                            //向vuex中同步token
                            userInfo.commit('saveToken',res.data.data)
                            //跳转主页
                            router.push({
                                path: '/'
                            })
                        }else{
                            if (res.data.errorCode) {
                                ElNotification({
                                    title : '用户登录异常',
                                    message : res.data.errorMessage,
                                    type : 'error'
                                })
                            }
                        }
                    });
                }
            },
            //重置
            resetForm: (name) =>{
                data.loginUser.constructor();
            }

        })
        return {data}
    },
})
